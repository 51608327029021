@import 'variables';
@import 'fonts';

@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css?family=Oswald:400,500,700&display=swap');
@import 'mixins';

@import 'material';
@import 'base';
@import 'rating';
@import 'prediction';
@import 'game-polls';
@import 'footer';
@import 'polls';
@import 'leaderboards';
@import 'battles';
@import 'unauthenticated';

@import 'components/header';
@import 'components/icons';
@import 'components/boxes';
@import 'components/round-nav';
@import 'components/buttons';
@import 'components/modals';
@import 'components/rating-scale';
@import 'components/countdown';

// Widget
@import 'widget/widget';

// Mobile App Widget
@import 'mobile/mobile';
