$white: #ffffff;
$black: #000000;

$font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-sky: 'SkyText', sans-serif;

$default-border-radius: 8px;

$color-success: #7ece65;
$color-success-saturated: #4dcc01;
$color-success-light: #dcf5cd;
$color-primary: #00abed;
$color-error-dark: #c62b2b;
$color-error: #fb7b7e;
$color-error-light: #fff2f2;
$color-accent: #f5f8fd;

$color-gray-accent: #dde7f1;
$color-gray-light: #eeeeee;
$color-gray-medium: #cccccc;
$color-gray: #686868;

$text-color: $black;

$box-content-padding: 12px 16px;
