.icon {
	display: block;

	svg {
		max-width: 100%;
		max-height: 100%;
		display: block;
	}

	img {
		max-width: 100%;
		height: auto;
		display: block;
	}
}
