$mobile-font-family: 'Oswald', sans-serif;
$mobile-font-weight-light: 300;
$mobile-color-gray-light: #eeeeee;
$mobile-spacer-color: $color-gray-medium;

@import 'rating';
@import 'header';

.app-mobile {
	height: 100%;
	display: flex;
	flex-direction: column;
	font-size: 14px;
	font-family: $mobile-font-family;
	background: white;
	overflow-y: auto;

	* {
		font-family: $mobile-font-family;
	}


	.app-sponsor .sponsor {
		&__text {
			text-transform: uppercase;
			color: white;
			text-decoration: none;
		}
	}

	&__content {
		background: white;
		display: flex;
		flex-direction: column;
		min-height: 0;
		flex-grow: 1;
		flex-shrink: 0;
	}

	&__container {
		padding-left: 16px;
		padding-right: 16px;
	}

	.app-bar {
		&__value {
			font-size: 18px;
		}

		&__title {
			text-transform: uppercase;
			font-weight: $mobile-font-weight-light;
		}
	}

	.app-dial {
		&-number {
			font-size: 18px;
		}

		&__title {
			font-size: 13px;
			text-transform: uppercase;
			font-weight: $mobile-font-weight-light;
		}
	}
}

.app-mobile-bar {
	background: black;
	color: white;
	text-align: center;
	font-size: 13px;
	padding: 8px 16px;
	text-transform: uppercase;
}

.app-mobile-button {
	border: 0;
	background: $color-primary;
	font-weight: normal;
	color: white;
	font-size: 20px;
	border-radius: 6px;
	padding: 12px 20px;
	display: block;
	width: 100%;
	text-transform: uppercase;
	font-family: $mobile-font-family;

	&--gray,
	&[disabled] {
		background: lighten($color-gray, 20%);
		cursor: not-allowed;
	}
}

// Prediction
.app-mobile-prediction {
	display: flex;
	flex-direction: column;
	min-height: 0;
	flex-grow: 1;
	transition: background .5s ease;

	&--stats {
		background: white;
	}

	&-inputs {
		display: flex;
		width: 100%;
	}

	&__button-container {
		padding-top: 16px;
		padding-bottom: 16px;
		background: $mobile-color-gray-light;
		display: flex;
		width: 100%;
		flex-direction: column;
		flex-grow: 1;
		min-height: 0;
	}

	.result-input-wrapper {
		background: white;
		flex: 0 0 50%;

		&:first-child {
			border-right: 1px solid $mobile-spacer-color;
		}

		input {
			width: 100%;
			text-align: center;
			font-size: 32px;
			padding: 28px 0;
			font-weight: $mobile-font-weight-light;
			transition: all .3s ease;
		}
	}

}

.app-mobile-prediction-inputs--small {
	.result-input-wrapper input {
		padding: 16px 0;
		font-size: 24px;
	}
}

.app-mobile-prediction-bars {
	display: flex;
	width: 100%;

	&__wrapper {
		display: flex;
		align-items: flex-end;
		flex: 0 0 50%;
		padding-top: 16px;

		&:first-child {
			border-right: 1px solid $mobile-spacer-color;
		}
	}
}

.app-mobile-high-score-prediction-stats {
	display: flex;
	flex-direction: column;
	min-height: 0;
	flex-grow: 1;
}

.app-mobile-prediction-dials {
	display: flex;
	flex-wrap: wrap;

	&__headline {
		text-transform: uppercase;
		text-align: center;
		padding: 0 16px;
		display: block;
		width: 100%;
		margin-bottom: 8px;

		@media(max-width: 350px) {
			font-size: 13px;
		}
	}

	&__side {
		flex: 0 0 50%;
		width: 50%;
		padding: 16px 0;
		border-bottom: 1px solid $mobile-spacer-color;

		&:first-child {
			border-right: 1px solid $mobile-spacer-color;
		}

		.app-multi-dial {
			padding: 0 16px;
		}

		.app-dial {
			@media (max-width: 360px) {
				margin-right: 8px !important;
			}
		}
	}
}


.app-mobile-post {
	&__tendency {
		display: flex;
		padding: 24px 0;
		position: relative;

		&-center {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			text-transform: uppercase;

			> div {
				max-width: 120px;
				text-align: center;

				@media(max-width: 380px) {
					max-width: 100px;
				}

				@media(max-width: 340px) {
					max-width: 80px;
				}
			}
		}

		&-side {
			flex: 0 0 50%;
			display: flex;
			justify-content: center;
		}
	}
}
