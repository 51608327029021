.poll {
}

.poll-question__text {
	text-align: center;
	font-size: 24px;
	padding: 8px 16px;

	@media(max-height: 330px) {
		font-size: 20px;
		padding-top: 4px;
		padding-bottom: 4px;
	}
}
