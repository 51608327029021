
.app-battle {
	--color-bg: #fff;
	// --color-bg: #dce7f1;
	background-color: var(--color-bg);
	&__spinner {
		margin: 0 auto;
	}
}

.battle-rail {
	--button-width: 32px;
	--item-width: 200px;

	// display: flex;
	align-items: center;
	position: relative;

	&__button {
		position: absolute;
		top: 0px;
		height: 100%;
		width: var(--button-width);
		padding: 0;
		background-color: transparent;
		border: none;
		display: flex;
		align-items: center;

		svg {
			width: 12px;
		}
		&[disabled] svg {
			opacity: 0.5;
		}

		&--left {
			left: 0px;
			background-image: linear-gradient(to right, var(--color-bg), var(--color-bg) 75%, transparent);
			justify-content: flex-start;
			z-index: 1;
		}

		&--right {
			right: 0px;
			background-image: linear-gradient(to left, var(--color-bg), var(--color-bg) 75%, transparent);
			justify-content: flex-end;
		}
	}

	&__scroller {
		width: 100%; //calc(100% - 2 * var(--button-width));
		overflow: hidden;
	}

	&__list {
		display: flex;
	}
	&__item {
		margin-right: 10px;
		&:first-child {
			margin-left: var(--button-width);
		}
		&:last-child {
			border-right: var(--button-width) solid transparent;
		}
	}

}

.battle-poll {
	min-width: var(--item-width);
	min-height: var(--item-width);
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	text-align: center;
	padding: 10px;
	text-transform: uppercase;
	position: relative;

	&--past {
		background-color: #fff;
		border: 1px solid #ccc;
	}
	&--active {
		background-image: linear-gradient(to bottom, #d31825, #99121c);
		color: #fff;
	}
	&--future {
		background-color: #f2f2f2;
		color: #cccbcb;
	}

	// &__type {
	// 	font-size: 10px;
	// }
	&__date {
		font-weight: 600;
	}
	&__date-day {
		font-size: 28px;
		// margin-top: 20px;
	}
	&--past &__date-day {
		color: #d31825;
	}
	&__date-month {
		margin-bottom: 20px;
	}

	&__state {
		font-weight: 600;
		font-size: 13.3333px;
		padding: 10px 20px;

		&--countdown {
			color: #000;
			display: flex;
			align-items: center;
			justify-content: center;
			svg {
				width: 20px;
				height: 20px;
				margin-right: 5px;
			}
		}
	}

	&__button {
		background-color: transparent;
		border: 1px solid rgba(255, 255, 255, 0.9);
		border-radius: 4px;
		font-weight: 600;
		padding: 10px 20px;
		color: #fff;
		text-transform: uppercase;
		font-family: $font-family;
		cursor: pointer;
	}

	&__lock-icon {
		position: absolute;
		bottom: 16px;
		right: 16px;
		width: 24px;
		height: 24px;
		svg {
			fill: #cccbcb;
		}

	}

}

.battle-overlay {

	&__backdrop {
		position: fixed;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.75);
		display: flex;
		align-items: center;
		justify-content: center;

	}

	&__main {
		width: 100%;
		max-width: 600px;
	}

	&__poll {
		background-image: linear-gradient(to bottom, #d31825, #99121c);
		color: #fff;
		width: 120px;
		height: 120px;
		border-radius: 12px;
		padding: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		margin: 20px auto;
		font-weight: 600;
	}
	&__poll-day {
		font-size: 28px;
	}
}
