.footer,
.app-sponsor {
	background: white;
	width: 100%;
	display: flex;
	justify-content: center;
	position: relative;
	flex-shrink: 0;
	height: 36px;

	margin-bottom: 0;
	margin-top: 10px;
	padding:5px;
	border-radius:$default-border-radius;
	//border:1px solid $color-gray-accent;

	background-size: cover;
	background-position: center;

	h6{
		font-size:8px;
		position:absolute;
		top:-5px;
		text-transform:uppercase;
		left:8px;
		background:white;
		display:inline-block;
	}
	img {
		display: block;
		width: auto;
		max-width: 100%;
		height: 100%;
		margin: 0 auto;
	}

	.sponsor {
		display: block;
		text-decoration: none;
		cursor: pointer;
		width: 100%;

		&__text {
			font-size: 12px;
			margin-right: 8px;
			flex-shrink: 0;

			&--dark {
				color: $color-gray !important;
			}
		}
	}

	&--large {
		height: 60px;
		padding-top: 8px;
		padding-bottom: 8px;
	}

	&--no-sponsor {
		height: auto;
		background: $color-accent !important;
		justify-content: flex-end;
		padding: 0 16px 12px;

		img {
			width: 90px;
			margin: 0;
			margin-left: auto;
		}
	}
}
