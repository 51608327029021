.app-widget-post {
	display: block;

	&__result {

	}

	.prediction-side {
		border: 0 !important;
		padding: 0 !important;
	}

	.result-separator {
		display: none;
	}

	&__rating-headline {
		margin-top: 16px;
	}

	&__result-headline {
		font-size: 12px;
		font-weight: normal;
		line-height: 1;
	}

	&__result-score {
		font-size: 20px;
		display: block;
		text-align: center;
		line-height: 1.1;
	}


	.app-widget-rating__team-row--single {
		.box__content {
			display: flex;
			width: 100%;
			justify-content: center;
		}

		.app-widget-rate-team__scale {
			width: 100%;
			max-width: 350px;
		}

		.app-widget-rating__team-side {
			flex: 0 0 100%;
			width: 100%;
		}
	}
}
