.app-dial {

	display: flex;
	flex-direction: column;
	align-items: center;

	&__circle {
		display: flex;
		align-items: center;
		position: relative;

		.app-dial-overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
		}

		.app-dial-number-wrapper {
			display: flex;
			width: 100%;
			justify-content: center;
			align-items: baseline;
		}
	}

	&__title {
		margin-top: 3px;
		text-align: center;
		font-size: 12px;
		text-transform: uppercase;

		&--highlighted {
			color: $color-primary;
			font-weight: bold;
		}
	}
}

.app-dial-number {
	font-size: 14px;
	font-weight: bold;

	&.app-dial-number--decimal {
		line-height: 1;
		font-size: 16px;
	}

	&--highlighted {
		color: $color-primary;
	}
}


.app-multi-dial {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	width: 100%;

	.app-dial {
		flex-shrink: 0;
		flex-grow: 0;

		margin-right: 12px;

		&:last-child {
			margin-right: 0;
		}
	}
}
