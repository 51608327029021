$ratingFullWidthBreakpoint: 600px;

.app-widget-rating {

	&__container {
	}

	&__team-row {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		position: relative;

		&--single {
			justify-content: center;
		}
	}

	&__team-side {
		flex: 0 0 48%;
		width: 48%;
		@extend .app-box;
		padding-bottom: 0;
		margin-bottom: 8px;

		.app-multi-dial {
			margin: 8px 0 16px;
		}

		@media(max-width: $ratingFullWidthBreakpoint) {
			flex: 0 0 100%;
			width: 100%;
			margin-bottom: 24px;
		}
	}

	&__bottom {
		width: 100%;
		display: flex;
		justify-content: center;
		padding: 16px;
	}

	&__legend {
		padding: 0;
		margin: 8px auto;
		text-align: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		li {
			margin-right: 16px;
			margin-bottom: 4px;
			font-size: 11px;
		}
	}

	&__best-player {
		text-align: center;
		width: 100%;
		font-size: 11px;
		margin-top: 24px;
		text-transform: uppercase;
	}
}

.app-widget-rate-team {

	display: block;

	.box {
		margin-bottom: 0;
	}

	.box__content {
		padding: 0;
	}

	&__mvp-headline {
		text-align: center;
		padding: 24px 0 12px;
		font-weight: bold;
	}

	&__scale {
		position: relative;

		display: flex;
		align-items: center;
		justify-content: center;
		width: auto;
		background: white;
		border-top: 1px solid $widget-color-gray-medium;
		border-bottom: 1px solid $widget-color-gray-medium;
		margin-left: -8px;
		margin-right: -8px;

		.icon {
			$iconSize: 18px;
			flex: 0 0 $iconSize;
			width: $iconSize;
			height: $iconSize;

			svg {
				fill: $color-gray;
			}
		}

		ul {
			width: 100%;
			padding: 0;
			display: flex;
			justify-content: space-between;
		}

		&-item {
			$ratingScaleItemSize: 47px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			width: auto;
			flex: 1;
			height: $ratingScaleItemSize;
			text-align: center;
			cursor: pointer;
			border-right: 1px solid $widget-color-gray-medium;
			transition: height .4s ease;

			&--selected {
				background: $color-primary;

				.app-widget-rate-team__scale-item-text {
					color: white;
				}
			}

			&:last-child {
				border-right: 0;
			}

			.app-bar {
				margin-top: auto;
			}

			.app-bar__value {
				font-size: 14px;

				@media(max-width: 320px) {
					font-size: 12px;
				}
			}

			.app-bar__bar {
				width: 70%;
				max-width: 40px;
				min-height: 1px;
			}

		}
	}

	select {
		display: block;
		width: 100%;
		height: 48px;
		background: white;
		border: 0;
		border-radius: 0;
		font-size: 18px;
		padding: 0 8px;
		appearance: none;

		background-image: url(/assets/images/arrow-down.svg);
		background-position: right 16px center;
		background-repeat: no-repeat;
		background-size: 16px;

		&:invalid { color: $widget-color-gray; }

		@media(max-width: 600px) {
			font-size: 16px;
			height: 40px;
		}
	}

	&--finished {
		.box__content {
			background: transparent;
		}

		select {
			display: none;
		}

		.app-widget-rate-team__scale {
			border-top: 0;
			margin-left: 0;
			margin-right: 0;
			border-bottom: 0;

			&-item {
				height: 130px;
				flex-direction: column;
				padding-top: 8px;
				cursor: default;

				&--selected {
					background-color: transparent;
					font-weight: bold;

					.app-widget-rate-team__scale-item-text,
					.app-bar__value {
						color: $color-primary;
					}
				}
			}
		}
	}
}
