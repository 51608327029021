body {
	margin: 0;
	font-family: $font-family;
	font-weight: 500;
}

html,
body {
	height: 100%;
}

app-root {
	display: flex;
	height: 100%;
	flex-direction: column;
}

html {
	box-sizing: border-box;
}
*, *:before, *:after {
	box-sizing: inherit;
}

p {
	margin: 0 0 10px;
}
h1, h2, h3, h4, h5, h6 {
	margin: 0 0 10px;
}
h1 {
	font-size: 36px;
	@media (max-width: 480px) {
		font-size: 18px;
	}
}
ul {
	padding: 0;
	margin: 0;
	li {
		list-style: none;
	}
}

.app-root-height-container {
	width: 100%;
	margin: 0 auto;
	position: relative;
}

.standalone .app-root-height-container {
	max-width: 600px;
}

.mat-spinner {
	svg {
		width: 100% !important;
		height: 100% !important;
	}
}

.main-background {
	display: block;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50%;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.main-component {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
}

.main-container {
	width: 100%;
	max-width: 600px;
	margin: 0 auto;
	padding: 16px;
	padding-top: 8px;
	padding-bottom: 8px;
	position: relative;
}

.primary {
	@include primary-color();
}

.secondary {
	@include secondary-color();
}

.left {
	float: left;
}
.right {
	float: right;
}

.overlay {
	max-width: 350px;
	margin: 0 auto;
	display: block;
	padding-right: 8px;
	padding-left: 8px;

	.button {
		margin: 0;
		height: 32px;
	}

	.overlay__icon {
		@include secondary-color;
		border-radius: 50%;
		height: 42px;
		width: 42px;
		margin: 4px auto 12px;
		display: flex;
		align-items: center;
		justify-content: center;

		.icon {
			fill: white;
			width: 24px;
			height: 24px;
		}

		&.overlay__icon--error {
			@include primary-color;
		}

		@media(max-height: 230px) {
			height: 32px;
			width: 32px;

			.icon {
				width: 16px;
				height: 16px;
			}
		}

	}

	.overlay__content {
		padding-left: 24px !important;
		padding-right: 24px !important;
	}

	.overlay__title {
		font-size: 18px;
	}

	.overlay__subtitle {
		font-size: 14px;
		margin-bottom: 16px;
	}
}

snack-bar-container {
	&.success {
		background: $color-success;
	}

	&.error {
		background: $color-error;
	}
}
.cdk-overlay-container {
	position: fixed;
	z-index: 10000;
	color: $white;
}
.cdk-global-overlay-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: absolute;
	z-index: 1000;
}
.cdk-overlay-container, .cdk-global-overlay-wrapper {
	pointer-events: none;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}


.app-root-box {
	background-color: $color-accent;
	border: 1px solid $color-gray-accent;
	border-radius: $default-border-radius;
	padding: 0;
	display: block;
	overflow: hidden;

	&--bottom-padding {
		padding-bottom: 8px;
	}
}

.app-box {
	border-radius: $default-border-radius;
	border: 1px solid $color-gray-accent;
	overflow: hidden;
	background: white;
	width: 100%;
	padding: 8px;
}
