@font-face {
	font-family: 'SkyText';
	src: url('./fonts/SkyTextMedium/SkyTextMedium-Regular.eot');
	src: url('./fonts/SkyTextMedium/SkyTextMedium-Regular.eot?#iefix') format('embedded-opentype'),
	url('./fonts/SkyTextMedium/SkyTextMedium-Regular.woff2') format('woff2'),
	url('./fonts/SkyTextMedium/SkyTextMedium-Regular.woff') format('woff'),
	url('./fonts/SkyTextMedium/SkyTextMedium-Regular.ttf') format('truetype'),
	url('./fonts/SkyTextMedium/SkyTextMedium-Regular.svg#SkyTextMedium-Regular') format('svg');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'SkyText';
	src: url('./fonts/SkyTextRegular/SkyText-Regular.eot');
	src: url('./fonts/SkyTextRegular/SkyText-Regular.eot?#iefix') format('embedded-opentype'),
	url('./fonts/SkyTextRegular/SkyText-Regular.woff2') format('woff2'),
	url('./fonts/SkyTextRegular/SkyText-Regular.woff') format('woff'),
	url('./fonts/SkyTextRegular/SkyText-Regular.ttf') format('truetype'),
	url('./fonts/SkyTextRegular/SkyText-Regular.svg#SkyText-Regular') format('svg');
	font-weight: 400;
	font-style: normal;
}
