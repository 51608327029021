.app-dialog-no-padding {
	.mat-dialog-container {
		padding: 0 !important;
	}
}

.app-dialog-clean {
	max-width: none !important;

	.mat-dialog-container {
		background: transparent;
		box-shadow: none;
	}
}
