.prediction-container {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.result-separator {
		flex: 0 0 8%;

		.bullet {
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background: $white;
			margin-bottom: 12px;
			margin-left: auto;
			margin-right: auto;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.prediction-side {
		flex: 0 0 46%;
		width: 46%;
		position: relative;

		.result-input-wrapper {
			margin: 0;
			padding: 12px 0;
			display: table-cell;
			vertical-align: middle;
			text-align: center;
			overflow: hidden;

			@media(max-height: 300px) {
				padding: 4px 0;
			}

			@media(max-height: 280px) {
				padding: 0;
			}

			input {
				font-size: 28px;
				font-weight: bold;
				background: transparent;
				border: 0;
				text-align: center;
				outline: none;
				width: 100%;
				font-family: $font-family;

				&::placeholder {
					color: $black;
				}
			}
		}

		@media(max-height: 280px) {
			.box__content {
				padding-top: 8px;
				padding-bottom: 8px;
			}
		}
	}
}
