.app-leaderboard-root {
	height: 100%;

	--color-bg: 0, 0, 0;
	--opacity-bg: 0.8;
	--color-text: 255, 255, 255;
	--color-danger: 238, 34, 34;

	--color-header-bg: 255, 255, 255;
	--color-header-text: 68, 68, 68;

	--color-button: 34, 34, 204;
	--color-button-bg: 238, 238, 238;

	--border-radius: 4px;

	&__spinner {
		margin: 20px auto;
	}
}

.app-leaderboard {
	background-color: RGBA(var(--color-bg), var(--opacity-bg));
	color: RGB(var(--color-text));


}

.leaderboard-table {
	width: 100%;
	border-collapse: collapse;
	table-layout: fixed;
	margin-bottom: 0.4rem;

	@media (max-width: 360px) {
		font-size: 0.8rem;

		&__col-user {
			width: 50%;
		}
	}

	@media (min-width: 360px) {
		&__col-rank {
			width: 10%;
		}
		&__col-user {
			width: 50%;
		}
		&__col-score {
			width: 20%;
		}
	}


	&__cell {
		padding: 10px 15px;
		border-bottom: 1px solid RGBA(var(--color-text));

		&--head {
			text-transform: uppercase;
			font-weight: 200;
			background-color: RGB(var(--color-header-bg));
			color: RGB(var(--color-header-text));
		}

		&--rank, &--user {
			text-align: left;
		}

		&--score {
			text-align: center;
		}
	}

	&__user-name {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__score {
		display: flex;
		justify-content: center;
		align-items: center;

		.icon {
			margin-left: 1ch;
		}

		svg {
			fill: RGB(var(--color-text));
			width: 12px;
			height: 12px;
		}
	}
}

.leaderboard-group {
	&__wrap {
		padding: 10px 15px;
	}

	&__spinner {
		margin: 20px auto;
	}

	&__button, &__select {
		background-color: RGB(var(--color-button-bg));
		color: RGB(var(--color-button));
		border: 1px solid RGB(var(--color-button));
		border-radius: var(--border-radius);
		padding: 5px 10px;
		font-size: 16px;
		font-variant-caps: all-petite-caps;
		&:hover:not([disabled]) {
			box-shadow: inset 0 0 12px 2px RGBA(var(--color-button), 0.1);
		}
		& + & {
			margin-left: 5px;
		}

		&--danger {
			--color-button: var(--color-danger);
		}
		&[disabled] {
			filter: grayscale(1);
		}
	}

	&__controls {
		display: flex;
		align-items: center;
	}
	&__switch-group {
		margin-right: 5px;
	}
	&__button--create {
		margin-left: auto;
	}

	&__name {
		text-transform: uppercase;
		font-weight: 200;
		font-size: 24px;
	}

	&__actions {
		padding: 10px 0;
		display: flex;
	}
	&__button--back {
		margin-right: auto;
	}

	&__admin-group {
		margin: 10px 0;
		padding-left: 19px;
	}
	&__admin-group-title {
		margin-left: -19px;
		text-transform: uppercase;
		font-weight: 200;
	}

	&__input-group {
		display: block;
		margin: 10px 0;
	}
	&__input {
		border: 1px solid RGB(var(--color-button));
		border-radius: var(--border-radius);
		padding: 5px 10px;
		font-size: 16px;
	}

	&__member {
		padding: 5px 0;
		display: flex;
		align-items: center;
		border-bottom: 1px solid RGBA(var(--color-text), 0.5);
	}
	&__member-name {
		margin-right: auto;
	}
	&__member-role {
		background-color: RGB(var(--color-header-bg));
		color: RGB(var(--color-header-text));
		font-size: 10px;
		padding: 2px;
		text-transform: uppercase;
		border-radius: 2px;
		margin-right: 5px;
	}
}
