.app-rating-scale {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;

	.icon {
		$iconSize: 18px;
		flex: 0 0 $iconSize;
		width: $iconSize;
		height: $iconSize;

		svg {
			fill: $color-gray;
		}
	}

	ul {
		width: 100%;
		padding: 0;
		display: flex;
		justify-content: space-between;
	}

	&-item {
		$ratingScaleItemSize: 47px;
		background: white;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		width: auto;
		flex: 1;
		height: $ratingScaleItemSize;
		text-align: center;
		cursor: pointer;
		border-right: 1px solid $color-gray-medium;
		transition: height .4s ease;

		&--selected {
			background: $color-primary;

			.app-rating-scale-item-text {
				color: white;
			}
		}

		&:first-child {
			border-left: 1px solid $color-gray-medium;
		}

		.app-bar {
			margin-top: auto;
		}

		.app-bar__value {
			font-size: 14px;

			@media(max-width: 320px) {
				font-size: 12px;
			}
		}

		.app-bar__bar {
			width: 70%;
			max-width: 40px;
			min-height: 1px;
		}
	}

	&--finished {
		border-bottom: 1px solid $color-gray-medium;

		select {
			display: none;
		}

		.app-rating-scale-item {
			height: 130px !important;
			flex-direction: column;
			padding-top: 8px;
			cursor: default;

			&--selected {
				background-color: white;
				font-weight: bold;

				.app-rating-scale-item-text {
					color: $color-primary;
				}
			}
		}
	}
}
