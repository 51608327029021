.widget-button {
	border: 0;
	background: $widget-color-primary;
	font-weight: bold;
	color: white;
	font-size: 14px;
	border-radius: 4px;
	padding: 8px 20px;

	&--gray,
	&[disabled] {
		background: lighten($widget-color-gray, 20%);
		cursor: not-allowed;
	}
}
