.rating {
	margin-bottom: 20px;

	&__scale {
		background: $white;
		position: relative;

		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		.icon {
			$iconSize: 18px;
			flex: 0 0 $iconSize;
			width: $iconSize;
			height: $iconSize;

			svg {
				fill: $color-gray;
			}
		}

		ul {
			width: 100%;
			padding: 0 8px;
			display: flex;
			justify-content: space-between;
		}

		&-item {
			$ratingScaleItemSize: 36px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 20px;
			width: $ratingScaleItemSize;
			height: $ratingScaleItemSize;
			text-align: center;
			cursor: pointer;
			border-radius: 50%;

			&--selected {
				@include secondary-color;
				color: $white;
			}
		}
	}

	.rating__mvp {
		padding: 0 !important;
		overflow: hidden;
		background: $color-gray-light !important;


		&.rating__mvp--open {
			.rating__player-list {
				display: block;
			}

			.rate-player-button .icon {
				@include rotate(180deg);
			}
		}

		.rating__player-list {
			display: none;

			li {
				width: 100%;
				background: $color-gray-light;
				padding: $box-content-padding;
				border-bottom: 1px solid darken($color-gray-light, 10%);
				cursor: pointer;
				text-align: left;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;

				&.rating__player--selected {
					@include secondary-color;
					color: white;
				}

				&:last-child {
					border-bottom: 0;
				}
			}
		}

		.rate-player-button {
			display: flex;
			align-items: center;
			padding: $box-content-padding;
			border-top: 1px solid darken($color-gray-light, 10%);

			span {
				display: block;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				margin-right: 16px;
			}

			.icon {
				margin-right: 0;
				margin-left: auto;
				width: 24px;
				height: 24px;
				flex: 0 0 24px;
			}
		}
	}
}
