.app-unauthenticated {
	display: block;
	text-align: center;
	color: #888;
	background-color: white;
	width: 100%;
	height: 180px;
	z-index: 500;

	.app-unauthenticated__inner {
		padding: 48px 24px;
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		align-items: center;
		justify-content: center;
	}

	p {
		margin-top: 0;
		margin-bottom: 24px;
	}
}
