.app-mobile-rating {
	display: flex;
	width: 100%;
	flex-direction: column;
	min-height: 0;
	flex-grow: 1;
	flex-shrink: 0;

	.app-rating-scale-item {
		font-size: 28px;
		height: 60px;
		font-weight: $mobile-font-weight-light;
	}

	.app-mobile-rate-team__scale-bar {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;

		&-side {
			color: $color-gray-medium;
			font-size: 11px;
			margin-right: 16px;

			&:last-child {
				margin-left: 16px;
				margin-right: 0;
			}
		}
	}

	&__button-container {
		padding: 16px;
		background: $mobile-color-gray-light;
		flex-grow: 1;
		display: flex;
		width: 100%;
		flex-direction: column;
		min-height: 0;
	}

	&__dials {
		margin: 32px 0;
		padding: 0 16px;
	}

	&__stats {
		display: flex;

		&-side {
			flex: 0 0 50%;
			width: 50%;
			border-right: 1px solid $mobile-spacer-color;

			.app-rating-scale-item {
				border-right: 0;
				font-size: 16px;
				padding-top: 0;
				height: 120px !important;

				&:first-child {
					border-left: 0;
				}

				&-text {
					width: 100%;
					padding: 8px 0;
				}
			}

			.app-bar {
				flex-grow: 1;
				justify-content: flex-end;
				background: $mobile-color-gray-light;
			}

			.app-bar__value {
				font-size: 12px !important;
				font-weight: normal;
			}

			.app-bar__bar {
				max-width: 20px;
			}

			.app-multi-dial {
				flex-direction: column;
				align-items: center;

				.app-dial {
					margin: 0;
					margin-bottom: 16px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

.app-mobile-rate-team {
	display: block;

	select {
		width: 100%;
		padding: 16px;
		background: white;
		appearance: none;
		border: 0;
		border-radius: 0;
		text-align: center;
		font-size: 24px;
		font-weight: $mobile-font-weight-light;

		background-image: url(/assets/images/arrow-down.svg);
		background-position: right 16px center;
		background-repeat: no-repeat;
		background-size: 16px;

		&:invalid { color: $widget-color-gray; }
	}
}
