.round-nav {
	margin-right: 0;
	margin-left: auto;
	width: 100%;
	max-width: 120px;
	border-radius: $default-border-radius;
	text-align: center;
	@include gray-color;
	display: flex;
	position: relative;
	background: white;
	border: 2px solid rgba(#000, .2);

	.round-nav__arrow {
		width: 50%;
		background: transparent;
		height: 36px;
		border: 0;

		.icon {
			width: 16px;
			height: 16px;
			margin: 0 auto;
		}
	}

	.round-nav__indicator {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateX(-50%);
		color: $black;
		font-size: 16px;
		line-height: 1;
		margin-top: -8px;
	}

	&:before, &:after {
		content: '';
		position: absolute;
		height: 6px;
		width: 2px;
		background: #c7c7c7;
		left: 0;
		right: 0;
		margin: 0 auto;
	}

	&:before {
		top: 0;
	}

	&:after {
		bottom: 0;
	}
}
