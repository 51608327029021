.app-poll-root {
	height: 100%;

	&__spinner {
		width: 32px !important;
		height: 32px !important;
		margin: 48px auto;
	}
}

.app-poll-box {
	@extend .app-box;
	padding: 0;
}

.app-poll {
	> .app-root-box {
		flex-shrink: 0;
	}

	&__root {
		padding: 8px 8px 0;
		background-size: cover;
		background-position: center;
	}

	&__intro {
		text-transform: uppercase;
		text-align: center;

		img {
			max-width: 100%;
			height: auto;
			display: block;
		}

		button {
			font-size: 18px;
			padding: 6px 20px;
			margin-top: 16px;
		}
	}

	&__intro-headline {
		font-size: 11px;
		margin-bottom: 8px;
		font-weight: normal;
	}

	&__unavailable-bar {
		background: $color-primary;
		color: white;
		padding: 8px;
		font-size: 24px;
		text-align: center;
		text-transform: uppercase;

		&--single {
			margin-top: -8px;
			margin-left: -8px;
			margin-right: -8px;
		}
	}

	&__intro-content {
		padding: 12px 24px;

		&--disabled {
			opacity: 0.2;
		}
	}

	&__subtitle {
		font-size: 16px;
		font-weight: normal;

		span {
			font-size: 14px;
			font-weight: normal;
		}
	}

	&__title {
		font-size: 24px;
		margin-bottom: 8px;
	}

	&__email-box {
		padding: 16px;
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: center;

		.mat-form-field {
			min-width: 70%;
		}

		.mat-checkbox {
			font-size: 14px;
			margin-bottom: 8px;
			display: block;

			.mat-checkbox-layout {
				white-space: normal;
				line-height: 1.2;
			}
		}

		.mat-error {
			font-size: 12px;
		}
	}

	&__email-title {
		font-size: 14px;
		margin-bottom: 32px;
		text-align: center;
	}

	&__header {
		display: flex;
		align-items: center;
		padding: 8px 8px 12px;
	}

	&__header-title {
		font-size: 18px;
		font-weight: 500;
		text-transform: uppercase;
		text-align: center;
		margin: 0;
		width: 100%;
		padding: 0 16px;

		@media (max-width: 400px) {
			font-size: 16px;
		}
	}

	&__footer {
		position: relative;
		display: flex;
		align-items: center;
		height: 48px;
		justify-content: space-between;
		padding: 0 16px 0 16px;
		margin-left: -8px;
		margin-right: -8px;
	}

	&__sponsored-notice {
		font-size: 9px;
		color: darken($color-gray-accent, 10%);
		font-weight: normal;
	}

	&__logo {
		margin-left: auto;
		width: 80px;
		position: relative;
		right: 2px;
		height: auto;
		display: block;
	}

	&__question-index {
		text-align: center;
		margin-left: auto;
		flex-shrink: 0;

		span {
			display: block;
			font-size: 11px;
			text-transform: uppercase;
		}
	}

	&__countdown {
		display: flex;
		flex-direction: row;
	}

	&__countdownSeconds {
		color: $color-primary;
		font-weight: 500;
		//width: 32px;

		text-align: center;
	}

	&__extraTenSeconds {
		color: #8fc649;
		font-weight: 500;
		//width: 32px;
		margin-left:3px;
		text-align: center;
	}

	.mat-flat-button {
		text-transform: uppercase;
		color: white;
	}

	&__button-wrapper {
		position: absolute;
		top: 0;
		left: -5px;
		width: calc(100% + 10px);
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		@media (max-width: 350px) {
			justify-content: flex-start;
		}
	}

	&--headline-light {
		.app-poll__header,
		.app-poll__countdown,
		.app-poll__sponsored-notice {
			color: white;
		}
	}
}

.app-poll-jokers {
	display: flex;
	flex-direction: column;

	&__jokers-wrapper {
		display: flex;
		flex-direction: row;
		flex: 1;
		background: transparent;
		margin-top: 91px;
		margin-bottom: 63px;
		justify-content: center;
	}

	&__icon-container {
		display: flex;
		justify-content: flex-end;
	}

	&__close-icon {
		background: transparent;
		cursor: pointer;
		width: 25px;
		height: 25px;
		color: black;
	}

	&__joker {
		width: 116.5px;
		height: 92px;
		display: flex;
		justify-content: center;
		cursor: pointer;
		position: relative;
		&.used {
			&::before {
				content: "";
				display: inline-flex;
				background-image: url("/assets/images/joker-disabled.png");
				background-size: 36;
				cursor: default;
				background-repeat: no-repeat;
				background-position: center;
				pointer-events: none;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				z-index: 2;
			}

			&::after {
				content: "";
				display: inline-flex;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
				background-color: rgba(255, 255, 255, 0.8);
			}

			> div {
				opacity: 0.8;
			}
		}
	}

	&__joker-fifty_fifty {
		background-color: #07d1ad;
	}
	&__joker-add_ten_seconds {
		background-color: #8fc649;
	}
	&__joker-skip_question {
		background-color: #e6be0a;
	}
	&__joker-alternative_question {
		background-color: #de9e49;
	}
	&__joker-audience {
		background-color: #cf5acd;
	}

	&__joker-icon {
		// margin-top: 15px;
		margin-bottom: 8px;
		color: white;
		background: transparent;
	}

	&__joker-label-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&__joker-label {
		text-align: center;
		color: white;
		font-family: Rubik;
		font-size: 11px;
		padding-left: 15px;
		padding-right: 15px;
	}
}

.show-jokers {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	cursor: pointer;
	z-index: 10;
	width: 89px;
	height: 32px;
	border: 1px solid $color-gray-accent;
	border-radius: 3px;
	background-color: white;

	&__titleWrapper {
		display: flex;
		flex: 1;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	&__icon {
		width: 26px;
		height: 18px;
	}
	&__label {
		padding-left: 5px;
		font-family: $font-family;
		color: black;
		font-size: 12px;
		font-weight: 500;
		text-transform: uppercase;
	}

	&__underline {
		display: flex;
		width: 100%;
		flex-direction: row;
		height: 4px;

		&--active-jokers {
			display: none;
		}
	}

	&__underline-fifty_fifty {
		display: flex;
		flex: 1;
		background-color: #07d1ad;
	}
	&__underline-add_ten_seconds {
		display: flex;
		flex: 1;
		background-color: #8fc649;
	}
	&__underline-skip_question {
		display: flex;
		flex: 1;
		background-color: #e6be0a;
	}
	&__underline-alternative_question {
		display: flex;
		flex: 1;
		background-color: #de9e49;
	}
	&__underline-audience {
		display: flex;
		flex: 1;
		background-color: #cf5acd;
	}

	&--active-available {
		background-color: transparent;
		border: none;
	}
}

.current-active-joker-footer {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.current-active-joker-title {
	color: white;
	font-family: Rubik;
	font-size: 11px;
	padding-left: 10px;
}

.app-poll-question {
	display: block;
	position: relative;
	flex-wrap: wrap;

	&--disabled {
		opacity: 0.2;

		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 1000;
			height: 100%;
			background: transparent;
		}


	}

	&__content {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
	}

	&__media {
		flex: 0 0 100%;
		margin-bottom: 16px;
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: center;
		align-items: center;

		> * {
			margin-bottom: 16px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		img {
			height: auto;
			display: block;
			width: 100%;
			max-width: 100%;
		}

		iframe {
			width: 100%;
		}
	}

	&__choices {
		flex: 1 100%;
		position: relative;
	}

	&__button-choices {
		padding: 24px;
		display: flex;
		width: 100%;
		justify-content: center;
		padding-bottom: 8px;
		flex-wrap: wrap;

		button {
			margin-right: 8px;
			margin-bottom: 16px;
			min-width: 100px;

			&:last-child {
				margin-right: 0;
			}
		}

		&--scale {
			button {
				min-width: 40px;
			}
		}
	}

	&__button-spinner {
		width: 32px !important;
		height: 32px !important;
		margin: 32px auto;
		display: block;
	}

	&__choice {
		background: white;
		padding: 12px 16px;
		min-height: 54px;
		display: flex;
		align-items: center;
		cursor: pointer;
		border-bottom: 1px solid $color-gray-accent;

		.mat-radio-label {
			white-space: normal;
		}

		&:last-child {
			border-bottom: 0;
		}

		&--correct {
			background: $color-success-light;

			.app-poll-bar {
				background: $color-success-saturated;
			}
		}

		&--incorrect {
			background: $color-error-light;

			.app-poll-bar {
				background: $color-error;
			}
		}

		&--audience {
			background: #f8e6f8;
		}

		&--disabled {
			cursor: default;
			opacity: 0.5;
			pointer-events: none;
			.mat-radio-container{
				visibility:hidden;
			}
		}
	}

	.mat-radio-label-content,
	&__answer-text {
		font-size: 14px !important;
	}

	&__answer-text {
		width: 100%;
		flex-grow: 1;

		span {
			display: block;
			margin-bottom: 2px;
		}
	}

	&__answer-icon {
		flex: 0 0 20px;
		margin-right: 6px;
	}

	&__stats {
		display: flex;
		align-items: center;
		width: 100%;
	}

	&__percentage {
		margin-left: auto;
		flex: 0 0 48px;
		font-size: 14px;
		text-align: right;

		&--active-joker {
			color: rgb(207, 90, 205);
		}
	}

	&__progress-bars {
		display: flex;
		flex-direction: row;
		flex: 1;
	}

	&__countdown-bar {
		height: 6px;
		background: $color-primary;
		top: 0;
	}

	&__extraSeconds-bar {
		height: 6px;
		background: #8fc649;
		top: 0;
	}
}

.app-poll-badge {
	background: $color-success-saturated;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	.mat-icon {
		width: 16px;
		height: 16px;
		font-size: 16px;
		margin: 0;
		color: white;
	}

	&--incorrect {
		background: $color-error;
	}
}

.app-poll-outro {
	&__box {
		padding: 32px 16px;
		text-align: center;
	}

	&__header {
		width: 100%;
		text-align: center;
	}

	&__headline {
		font-size: 18px;
		font-weight: 500;
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 8px;
	}

	p {
		margin: 0;
		font-size: 14px;
		font-weight: normal;
	}

	&__ratio {
		width: 100%;
		justify-content: center;
		display: flex;
	}

	&__ratio-item {
		margin-right: 10px;

		&:last-child {
			margin-right: 0;
		}

		span {
			margin-bottom: 4px;
			display: block;
		}
	}
}

.app-poll-bar {
	background: $color-primary;
	border-radius: $default-border-radius;
	height: 10px;
	display: block;
	width: 0;
	transition: width 0.5s ease;
}

@keyframes countdown {
	from {
		width: 100%;
	}
	to {
		width: 0%;
	}
}

@keyframes extraSeconds {
	0% {
		width: 100%;
	}
	100% {
		width: 0%;
	}
}
