.game-header {
	display: flex;
	align-items: center;
	margin-bottom: 8px;
	color: black;

	&__main {
		flex-shrink: 1;
		margin-right: 16px;
		min-width: 0;
	}

	&__title {
		font-size: 20px;
		margin-bottom: 2px;
	}

	&__subtitle {
		font-size: 16px;
		font-weight: 400;
		margin: 0;
	}

	@media(min-height: 350px) {
		margin-bottom: 16px;

		&__title {
			margin-bottom: 5px;
		}
	}

	@media(max-width: 350px) {
		&__title {
			font-size: 18px;
		}

		&__subtitle {
			font-size: 14px;
		}
	}

}

app-header {
	display: block;
}
