.button {
	width: 100%;
	border: 0;
	outline: none;
	border-radius: 5px;
	margin-bottom: 0;
	cursor: pointer;
	color: white;
	@include secondary-color;
	height: 56px;
	padding: 6px 16px;
	font-size: 18px;
	font-family: $font-family;

	.mat-button-wrapper {
		color: white;
		font-family: $font-family;
		white-space: normal;
		line-height: 1;
	}

	&.button--gray,
	&.button--disabled,
	&[disabled] {
		@include gray-color;
	}

	&.button--red {
		@include primary-color;
	}

	.button__text {
		font-size: 24px;
		line-height: 1.2;
		white-space: pre-wrap;
		display: block;
		text-align: center;
		font-family: $font-family;

		&.button__text--medium {
			font-size: 18px;

			@media(max-width: 460px) {
				font-size: 14px;
			}

			@media(max-width: 360px) {
				font-size: 12px;
			}
		}

		&.button__text--small {
			font-size: 14px;

			@media(max-width: 460px) {
				font-size: 12px;
			}
		}
	}
}
