.box {
	border-radius: $default-border-radius;
	margin-bottom: 8px;
	position: relative;
	z-index: 1;

	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 100;
		background: rgba(#000, .6);
	}

	&__header {
		background: $color-primary;
		display: block;
		text-align: center;
		padding: 4px 10px;
		color: white;
		font-size: 12px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;

		border-top-left-radius: $default-border-radius;
		border-top-right-radius: $default-border-radius;

		+ .box__content {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}

	&__content {
		padding: $box-content-padding;
		background: white;
		color: black;
		text-align: center;

		&.box__content--no-bottom-padding {
			padding-bottom: 0;
		}

		&:last-child {
			border-bottom-left-radius: $default-border-radius;
			border-bottom-right-radius: $default-border-radius;
		}
	}

	.mat-spinner {
		margin: 16px auto;
		width: 32px !important;
		height: 32px !important;

		svg {
			width: 32px !important;
			height: 32px !important;
		}
	}
}

.box-dropdown-wrapper {
	padding: 0 !important;
	overflow: hidden;
	background: $color-gray-light !important;
}

.box-dropdown {
	&--open {
		.box-dropdown__list {
			display: block;
		}

		.box-dropdown__button .icon {
			@include rotate(180deg);
		}
	}

	&__list {
		display: none;
	}

	&__item {
		width: 100%;
		background: $color-gray-light;
		padding: $box-content-padding;
		border-bottom: 1px solid darken($color-gray-light, 10%);
		cursor: pointer;
		text-align: left;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;

		&--selected {
			@include secondary-color;
			color: white;
		}

		&:last-child {
			border-bottom: 0;
		}
	}

	&__button {
		display: flex;
		align-items: center;
		padding: $box-content-padding;
		border-top: 1px solid darken($color-gray-light, 10%);

		span {
			display: block;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			margin-right: 16px;
		}

		.icon {
			margin-right: 0;
			margin-left: auto;
			width: 24px;
			height: 24px;
			flex: 0 0 24px;
		}
	}
}
