$widget-color-gray-light: #f4f4f4;
$widget-color-gray-medium: #dfdfdf;
$widget-color-gray: #565b61;
$widget-color-primary: #d1021c;
$widget-color-playersvote: #3d84e8;

@import 'buttons';
@import '../components/dial';
@import '../components/bars';
@import 'rating';
@import 'post';

$widget-prediction-padding: 12px;

.widget-header {
	width: 100%;
	text-align: center;
	padding: 8px 24px;
	font-size: 18px;
	text-transform: uppercase;
}

.main-container--widget {
	max-width: none;
	padding: 0;
}

.app-widget {
	display: flex;
	flex-direction: column;
	font-size: 14px;
	background-color: transparent;

	> .app-root-box {
		flex-shrink: 0;
	}

	&__root {
		padding: 8px 8px 0;
	}

	&__quota-notice {
		text-align: center;
		font-weight: bold;
		font-size: 16px;
		padding: 64px 32px;
	}

	&__spinner {
		width: 48px !important;
		height: 48px !important;
		margin: 24px auto;
	}

	.box {
		box-shadow: none;
		border-radius: $default-border-radius;

		&__header {
			border-radius: 0;
			background-color: transparent;
			color: $text-color;

			font-size: 14px;
			font-weight: 500;
			text-transform: uppercase;

			@media(max-width: 400px) {
				font-size: 12px;
			}
		}

		&__content {
			border-radius: 0;
		}
	}

	.prediction-container {
		margin: 0 auto;
		padding: 12px;
		padding-bottom: 0;

		.result-separator {
			.bullet {
				background: black;
				width: 4px;
				height: 4px;
				margin-bottom: 6px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.prediction-side {
			margin-bottom: 0;
			@extend .app-box;

			.box__content {
				padding-top: 8px;
				padding-bottom: 8px;
			}
		}

		.result-input-wrapper {
			padding: 0;
			display: block;

			input {
				font-size: 18px;
				font-weight: normal;
			}
		}
	}

	.app-sponsor {
		width: auto;
	}
}

.app-widget-prediction-stats {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.app-widget-prediction-bars {
	margin-top: 16px;
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;

	&__wrapper {
		flex: 0 0 46%;
		width: 46%;
		overflow: visible;
		min-width: 0;

		&--center {
			flex: 0 0 8%;
			width: 8%;
		}
	}
}

.app-widget-prediction-dials {
	margin: 24px 0 16px;

	&__headline {
		text-transform: uppercase;
		font-size: 14px;
		text-align: center;
		margin-bottom: 8px;
		width: 100%;
	}
}

.app-widget-prediction {

	&__bottom {
		width: 100%;
		display: flex;
		justify-content: center;
		padding: 16px;

		&--no-padding {
			// padding: 0;
		}
	}

	.app-mobile-prediction-bars__wrapper:first-child {
		border-right: 0;
	}

	.app-mobile-high-score-prediction-stats {
	}

	.app-mobile-prediction-bars {
		padding: 0;
		justify-content: space-between;

		&__wrapper {
			width: 46%;
			flex: 0 0 46%;
		}
	}

	.app-mobile-prediction-dials {
		justify-content: space-between;
		margin-top: 16px;

		&__headline {
			background: white;
			text-transform: uppercase;
			font-weight: 500;
			padding: 0 8px;
			margin: 0 0 12px;

			@media(max-width: 550px) {
				font-size: 11px;
			}
		}

		&__side {
			flex: 0 0 46%;
			width: 46%;
			@extend .app-box;

			@media(max-width: 550px) {
				.app-multi-dial {
					flex-wrap: wrap;

					.app-dial {
						width: 100%;
						margin: 0;
						margin-bottom: 16px;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}
