.mobile-header {
	color: white;
	display: block;
	text-transform: uppercase;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	background-color: $color-gray;

	&__bar {
		background: rgba(#000, .3);
		padding: 22px 12px;
		text-align: center;
	}

	&__team-wrapper {
		display: flex;
	}

	&__team {
		display: flex;
		flex: 0 0 50%;
		flex-direction: column;
		padding: 0 8px;
		padding-top: 16px;
		align-items: center;
		font-weight: bold;

		img {
			display: block;
			margin: 0;
			max-width: 90px;
			height: 90px;
			width: 100%;
			transition: transform .3s ease, filter .3s ease;
		}

		&--highlighted img {
			transform: scale(1.4);
		}

		&--grayscale {
			img {
				opacity: .5;
			}

			.mobile-header__name {
				opacity: .2;
			}
		}
	}

	&__team-bottom {
		margin: 12px 0;
	}

	&__name {
		display: block;
		transition: opacity .3s ease;
		text-align: center;
	}

	&__result {
		font-size: 22px;
		line-height: 1.1;
		display: block;
		text-align: center;
		font-weight: $mobile-font-weight-light;
	}
}
