.app-bar {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;

	&__value {
		text-align: center;
		width: 100%;
	}

	&__bar {
		display: block;
		width: 56px;
		min-height: 1px;
		position: relative;
	}

	&__bar-inner {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 0;
		background: $color-primary;
		transition: height 1s ease;
	}

	&__title {
		margin-top: 2px;
		font-size: 14px;
		text-align: center;
		width: auto;
	}
}
