/** Countdown **/
.app-countdown {
	width: 160px;
	border-radius: 4px;
	border: 3px solid rgba(#000, .2);
	display: flex;
	margin-left: auto;

	&__section {
		flex: 0 0 25%;
		background: white;
		text-align: center;
		color: black;
		padding: 4px 0;
		border-right: 2px solid rgba(#000, .4);

		&:last-child {
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
			border-right: 0;
		}

		&:first-child {
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
		}
	}

	&__section-title {
		font-size: 12px;
		margin-bottom: 1px;
		display: block;
	}

	&__section-number {
		font-size: 16px;
	}

	&--low-time {
		.app-countdown__section {
			background: $color-error-dark;
			color: white;

			border-right-color: darken($color-error-dark, 20%);
		}
	}

	@media(max-width: 360px) {
		width: 140px;

		&__section-title {
			font-size: 10px;
		}

		&__section-number {
			font-size: 11px;
		}
	}

	@media(max-width: 340px) {
		width: 110px;

		&__section-title {
			font-size: 9px;
		}
	}
}
