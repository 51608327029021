@mixin transition($time, $type) {
	-webkit-transition: $time $type;
	-moz-transition: $time $type;
	-ms-transition: $time $type;
	transition: $time $type;
}

@mixin rotate($degree) {
	-ms-transform: rotate($degree);
	-webkit-transform: rotate($degree);
	transform: rotate($degree);
}

@mixin primary-color() {
	background: linear-gradient(to right, #c62b2b 0%,#c02828 51%,#c42a2a 100%);
}
@mixin secondary-color() {
	background: #11d6d9;
}

@mixin gray-color() {
	background: #c8c8c7;
}
